import { connect } from 'react-redux';

const mapStateToProps = () => ({
  cards: {
    header: {
      row: {
        selectable: false,
      },
      cells: [
        {
          id: 1,
          type: 'sortable',
          direction: 'desc',
          text: 'Membership / Endorsement',
          key: 'membership',
          sortByType: 'string',
        },
        {
          id: 2,
          type: 'sortable',
          direction: 'desc',
          text: 'Start Date',
          key: 'start_at',
          sortByType: 'date',
        },
        {
          id: 3,
          type: 'sortable',
          direction: 'desc',
          text: 'Expiry Date',
          key: 'end_at',
          sortByType: 'date',
        },
      ],
    },
  },
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
