import { takeLatest, put, call } from "redux-saga/effects";
import axios from "axios";
import qs from "qs";
import { getCardsDetailsFulfilled } from "../actions/cardDetailsActions";
import { toggleLoading } from "../actions/spinnerActions";

const apiCall = (endPoint, data) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  };
  const URI = `https://services.nocnjobcards.org/api/${endPoint}`;
  return axios.post(URI, qs.stringify(data), { headers });
};

function* getMultipleCardDetails(cardIDs) {
  const promises = cardIDs.map((id) => apiCall("cardDetails", { cardID: id }));
  try {
    const responses = yield Promise.all(promises);
    const cards = responses.map(({ data }, idx) => ({
      id: cardIDs[idx],
      data: data.data,
    }));
    yield put(getCardsDetailsFulfilled(cards));
  } catch (e) {
    yield put({
      type: "GET_CARD_DETAILS_REJECTED",
      payload: e.response.data,
    });
  }
}

function* ApiResponse({ payload }) {
  try {
    yield put(toggleLoading(true));

    let response = yield call(() => apiCall("userData", payload));
    yield put({
      type: "API_RESPONSE",
      payload: response,
    });

    const { data = {} } = response;
    const { quartz_id: quartzID = null } = data.data || {};
    response = yield call(() => apiCall("cardsByUser", { quartzID }));
    yield put({
      type: "API_CARDS_RESPONSE",
      payload: response,
    });

    const dataCards = response.data.data;
    const cardIds = dataCards.map((card) => {
      const cardID = card.card_id;
      return cardID;
    });

    // GET CARDS
    yield getMultipleCardDetails(cardIds);

    // HS&E - RENEWAL TESTS
    response = yield call(() => apiCall("renewal-hse", { quartzID }));
    yield put({
      type: "GET_RENEWAL_TEST_FULFILLED",
      payload: response,
    });
  } catch (e) {
    yield put({
      type: "API_ERROR",
      payload: e.response.data,
    });
  } finally {
    yield put(toggleLoading(false));
  }
}

function* watchLastFetchUser() {
  yield takeLatest("SHOW_FORM", ApiResponse);
}

export default watchLastFetchUser;
