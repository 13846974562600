import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const CardTitle = ({ children, className }) => (
  <h2 className={`cardtitle ${className}`}>
    {children}
  </h2>
);

CardTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

CardTitle.defaultProps = {
  children: [],
  className: '',
};

export default CardTitle;
