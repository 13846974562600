import React from 'react';

import NOCNLogo from '../assets/images/logoNOCN.png';

const Header = () => (
  <header>
    <div className="container">
      <a href="https://www.nocnjobcards.org" target="_blank" rel="noopener noreferrer">
        <img src={NOCNLogo} alt="corporative NOCN logo" width="120" height="144" />
      </a>
      <h1>Online Card Checker</h1>
    </div>
  </header>
);
export default Header;
