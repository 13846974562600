import React from 'react';
import { compose } from 'redux';

import { renderList } from '../../helpers/arrayHelpers';
import { Image } from '../Image';
import { CardTitle, CardContent } from '../Text';
import { withProfileData } from '../../hocs/withData';

const CardIssued = (
  {
    dataSet = [],
    className = '',
  },
) => renderList(
  dataSet,
  (
    {
      item, key,
    },
  ) => <CardContent key={key} className={className}>{item}</CardContent>,
);

const Card = ({ Profile = {} }) => {

  return (
    <div className="personal-card">
      <Image personalImage={Profile.photo} />
      <div>
        <CardTitle>{`${Profile.forename} ${Profile.surname}`}</CardTitle>
        <CardContent className="bold">Individual ID: </CardContent>
        <CardContent>{Profile.quartz_id}</CardContent>
        {/* <CardContent className="bold block issued-title">Type of Cards Issued</CardContent>
        <div className="wrapper">
          <CardIssued
            dataSet={Profile.cardIssued || ['None']}
            className="block card-issued"
          />
        </div> */}
      </div>

    </div>
  );
};

export default compose(
  withProfileData,
)(Card);
