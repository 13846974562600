import React from 'react';
import { TableRow, TableHead } from '@material-ui/core';

import TypeCell from './TypeCell';

const Selectable = ({ selectable = false, onChange = () => { } }) => {
  if (!selectable) {
    return null;
  }
  const handleOnChange = (event) => onChange(event.target.checked);
  return (
    <TypeCell item={{ type: 'checkbox' }} onClick={handleOnChange} />
  );
};

const THeader = (props) => {
  const { cards } = props;
  return (
    <TableHead>
      <TableRow>
        <Selectable
          selectable={cards.header.row.selectable || false}
          onChange={props.onSelectAllToggle}
        />
        {cards.header.cells.map((item, index) => (
          <TypeCell key={`cell${index}`} item={item} index={index} {...props} />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default THeader;
