import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies
import InputTitle from './InputTitle';

const InputArea = ({
  labelTitle, placeholder, onChange, type, value, className, required, name,
}) => {
  const handleOnChange = (e) => onChange(e.target);
  return (
    <>
      <label>
        <InputTitle>{labelTitle}</InputTitle>
        <input type={type} placeholder={placeholder} name={name} className={className} onChange={handleOnChange} value={value} required={required} />
      </label>
    </>
  );
};

InputArea.propTypes = {
  labelTitle: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

InputArea.defaultProps = {
  labelTitle: '',
  placeholder: '',
  onChange: () => { },
  type: 'text',
  value: '',
  className: 'textInput',
  name: '',
  required: false,
};
export default InputArea;
