const actionTypes = {
  toggleLoading: 'TOGGLE_LOADING',
};

export const toggleLoading = (isLoading) => ({
  type: actionTypes.toggleLoading,
  payload: isLoading,
});

export default actionTypes;
