const INITIAL_STATE = {
  data: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'API_CARDS_RESPONSE':
      return {
        ...state,
        data: payload.data.data,
      };
    case 'API_CARDS_ERROR':
      return {
        ...state,
        status: payload.code,
      };
    case 'RESET_API_CARDS':
      return INITIAL_STATE;
    default:
      return state;
  }
};
