import React from 'react';

import Header from '../Header';
import Footer from '../Footer';
import ScrollToTop from './ScrollToTop';

export default ({ children = null, id = '' }) => (
  <>
    <ScrollToTop />
    <Header />
    <div id={id}>
      <div className="container">
        {children}
      </div>
    </div>
    <Footer />
  </>
);
