import React from 'react';
import { CircularProgress } from '@material-ui/core';
import './CardLoader.scss';

const CardLoader = () => (
  <div className="card-loader">
    <h3>Your card details are loading</h3>
    <p>This process might take a while</p>
    <CircularProgress />
  </div>
);

export default CardLoader;
