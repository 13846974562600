import React from 'react';

import { PageTitle } from '../../Text';
import CPCSLogo from '../../../assets/images/logoCPCS.png';
import CISRSLogo from '../../../assets/images/logoCISRS.png';


export default () => (
  <div className="right-content">
    <PageTitle className="invert">
      Searching Note
    </PageTitle>
    <span>Temporary National Insurance numbers are not recognised in this search function.</span>
    <p>
      <span>The card checker will not show details for anyone under 18 years of age.</span>
      <span>Contact the relevant card scheme helpline:</span>
      <span>
        CISRS (scaffolding) on
        <a href="tel:+03009991177" className="external-link">
          <strong> 0300 999 1177</strong>
          {' '}
          option 1, then option 2
        </a>
      </span>
      <span>
        CPCS (plant) on
        <a href="tel:+03009991177" className="external-link">
          <strong> 0300 999 1177</strong>
          {' '}
          option 1, then option 1
        </a>
      </span>
    </p>
    <p>Search and view CISRS and CPCS cards, and tests for individuals</p>
    <p>
      If you suspect the card you have checked is fraudulent, email details of the person and copy of any card produced to the relevant card scheme email address:
      <a href="mailto:CISRS@jobcards.org" className="external-link"><strong> CISRS@jobcards.org</strong></a>
      {' '}
      or
      <a href="mailto:CPCS@jobcards.org" className="external-link"><strong> CPCS@jobcards.org</strong></a>
    </p>
    <div className="logos">
      <a href="http://cisrs.org.uk" target="_blank" rel="noopener noreferrer">
        <img src={CISRSLogo} alt="corporative CISRS logo" />
      </a>
      <a href="https://www.nocnjobcards.org/CPCS" target="_blank" rel="noopener noreferrer">
        <img src={CPCSLogo} alt="corporative CPCS logo" />
      </a>
    </div>
  </div>
);
