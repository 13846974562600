import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const Button = (
  {
    classButton, children, disabled, href, type, onPress, buttontext,
  },
) => {
  const renderChildren = () => {
    if (disabled) {
      return (
        <span>{buttontext}</span>
      );
    }
    return (
      children
    );
  };

  return (
    <button
      type={type}
      className={`button ${classButton}`}
      href={href}
      disabled={disabled}
      onClick={onPress}
      buttontext={buttontext}>
      {renderChildren()}
    </button>
  );
};

Button.propTypes = {
  buttontext: PropTypes.string,
  classButton: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.string,
  onPress: PropTypes.func,
};

Button.defaultProps = {
  buttontext: '',
  classButton: '',
  children: [],
  disabled: false,
  href: null,
  type: 'button',
  onPress: () => { },
};

export default Button;
