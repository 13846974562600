import React from 'react';

import {
  TableCell, TableSortLabel, Checkbox, ListItemText,
} from '@material-ui/core';

const Comp = {
  selectAllBox: Checkbox,
  sortable: TableSortLabel,
  checkbox: Checkbox,
  text: ListItemText,
};

const TableComponent = (props) => {
  const {
    item, index, onSelectAllSelected, onSelectSortSelected,
  } = props;

  const ComponentProps = {
    selectAllBox: { checked: item.checked, onChange: () => onSelectAllSelected(index) },
    sortable: { text: item.text, direction: props.sortBy === item.key ? props.direction : 'desc', onClick: () => onSelectSortSelected(item) },
    checkbox: { checked: props.checked, onClick: props.onClick },
    text: { text: item.text },
  };
  return ComponentProps[item.type];
};

const TCell = ({ item, ...props }) => {
  const { type = 'text' } = item;
  const Component = Comp[type];

  const ComponentProps = TableComponent({ item, ...props });
  return (
    <TableCell>
      <Component {...ComponentProps}>
        {item.text}
      </Component>
    </TableCell>
  );
};

export default TCell;
