import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRenewalTestData } from '.';


const formatDataTable = (
  data,
) => {
  const dataTable = Object.values(data).map((rowData) => {
    return {
      row: {
        selectable: false,
      },
      cells: [
        { text: 'Membership / Endorsement' },
        { text: rowData.name },
        { text: 'Type' },
        { text: rowData.type },
        { text: 'Start Date' },
        { text: rowData.start_at },
      ],
      item: rowData,
    };
  });

  return dataTable;
};

const mapStateToProps = ({ RenewalTest }) => {
  const dataTest = formatDataTable(RenewalTest.data);
  return { dataTest };
};

export default (WrappedComponent) => compose(
  connect(
    mapStateToProps,
    null,
  ),
  withRenewalTestData,
)(WrappedComponent);
