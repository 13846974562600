import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const RowJustifyEnd = ({ children }) => (
  <div className="RowAlignEnd">
    {children}
  </div>
);

RowJustifyEnd.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

RowJustifyEnd.defaultProps = {
  children: [],
};

export default RowJustifyEnd;
