export default {
  cards: [
    'labourer',
    'labourer-card',
    'trainee',
    'trainee-card',
    'scaffolders',
    'scaffolders-card',
    'advanced',
    'advanced-card',
    'supervisor',
    'supervisor-card',
    'basic-inspection',
    'basic-inspection-card',
    'advanced-inspection',
    'advanced-inspection-card',
    'base',
    'base-card',
    'overseas-scaffolder-level-1',
    'overseas-scaffolder-level-1-card',
    'overseas-scaffolder-level-2',
    'overseas-scaffolder-level-2-card',
    'overseas-scaffolder-level-3',
    'overseas-scaffolder-level-3-card',
    'overseas-scaffolder-level-4',
    'overseas-scaffolder-level-4-card',
    'overseas-scaffolder-supervisor',
    'overseas-scaffolder-supervisor-card',
    'overseas-scaffolder-basic-inspection',
    'overseas-scaffolder-basic-inspection-card',
    'overseas-scaffolder-advanced-inspection',
    'overseas-scaffolder-advanced-inspection-card',
    'cisrs--scaffolder-',
  ],
};
