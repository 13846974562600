import React, { useState } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import _ from 'underscore';
import { Redirect } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';
import InputText from './InputText';
import InputCheckbox from './InputCheckbox';
import Select from './Select';
import Form from './Base';
import { Row, ThreeRows } from '../Structure';
import { fillArrayWithNumbers } from '../../helpers/arrayHelpers';
import withFormActions from '../../hocs/withActions/withFormActions';
import withFormDetails from '../../hocs/withData/withFormDetails';
import { withProfileData } from '../../hocs/withData';
import { ErrorContent } from '../Content/HomeScreen';


const dayOfMonth = [
  'DD',
  ...fillArrayWithNumbers(1, 31),
];

const Months = [
  'MM',
  ...moment.months(),
];

const minAge = 16;
const maxAge = 100;
const currentYear = moment().format('YYYY');
const firstYear = currentYear - maxAge;
const yearsSpan = moment().format('YYYY') - firstYear - minAge + 1;

const Years = [
  'YYYY',
  ...fillArrayWithNumbers(firstYear, yearsSpan).reverse(),
];


const HomeForm = ({
  SaveForm = (e) => { }, CardForm = {}, Profile = {}, RemoveStatus = () => { },
}) => {
  const [values, setValues] = useState(CardForm);
  const recaptchaRef = React.createRef();
  const Redirection = (status) => {
    const statusData = status.redirect || {};
    const statusCode = statusData.code;
    const statusTitle = statusData.message;
    switch (statusCode) {
      case 200:
        RemoveStatus(statusTitle);
        return (
          <Redirect to="/user" />
        );
      case 404:
      case 403:
        RemoveStatus(statusTitle);
        return (
          <Redirect to="/error" />
        );
      case 422:
      case 500:
        RemoveStatus(statusTitle, statusCode);
        return (
          <Redirect to="/" />
        );
      default:
        return (
          <Redirect to="/" />
        );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const recaptchaValue = recaptchaRef.current.getValue();
    // if (!recaptchaValue.length) {
    //   return;
    // }
    SaveForm({ ...values, recaptcha: 'whatever' });
  };

  const updateField = (event) => {
    const { name, value } = event;
    values[name] = value;
    setValues({ ...values });
  };

  const updateChecked = (event) => {
    const { name, checked } = event;
    values[name] = checked;
    setValues({ ...values });
  };

  const updateRecaptcha = (event) => {
    values.recaptcha = event;
    setValues({ ...values });
  };

  const RenderErrorMessage = () => {
    const statusCode = Profile.statusCode;
    if (statusCode === 422 || statusCode === 500) {
      return (
        <Row>
          <ErrorContent statusCode={statusCode} />
        </Row>
      );
    }
    return null;
  };

  return (
    <>
      <Redirection redirect={Profile.status} />
      <Form onSubmit={handleSubmit}>
        <Row>
          <InputText
            name="surname"
            labelTitle="Surname"
            placeholder="Type your surname here"
            value={values.surname}
            onChange={updateField}
            required={!(_.size(values.day) && _.size(values.month) && _.size(values.year)) > 0}
          />
        </Row>
        <ThreeRows>
          <Select
            name="day"
            options={dayOfMonth}
            value={values.day}
            onChange={updateField}
          />
          <Select
            name="month"
            options={Months}
            value={values.month}
            onChange={updateField}
          />
          <Select
            name="year"
            options={Years}
            value={values.year}
            onChange={updateField}
          />
        </ThreeRows>
        <Row>
          <InputText
            name="id"
            labelTitle="Individual ID/Registration Number"
            placeholder="Type your number here"
            value={values.id}
            onChange={updateField}
            required={_.isEmpty(values.nin)}
          />
        </Row>
        <Row>
          <InputText
            name="nin"
            labelTitle="National Insurance Number"
            placeholder="Type your number here"
            value={values.nin}
            onChange={updateField}
            required={_.isEmpty(values.id)}
          />
        </Row>
        <RenderErrorMessage />
        <Row>
          <InputCheckbox
            name="gdpr"
            onChange={updateChecked}
            required
          >
            I acknowledge and agree that NOCN accepts no liability whatsoever in contract, tort or otherwise for any loss or damage caused by or arising directly or indirectly in connection with any use or reliance on the information provided, except to the extent that such liability cannot be excluded by law.
          </InputCheckbox>
        </Row>
        <Row>
          <InputCheckbox
            name="privacy"
            onChange={updateChecked}
            required
          >
            <span>I agree to the Terms </span>
            <a href="https://www.nocnjobcards.org/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </InputCheckbox>
        </Row>
        <Row>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfxZtIUAAAAAA1_vEhqDFGJqg7NNwcpyydHrXAx"
            onChange={updateRecaptcha}
          />
        </Row>
        <Row>
          <InputText type="submit" className="button" value="Search" />
        </Row>
      </Form>
    </>
  );
};

export default compose(
  withFormDetails,
  withFormActions,
  withProfileData,
)(HomeForm);
