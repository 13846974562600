import React from 'react';

export default () => (
  <>
    <span>
      One or more fields are incorrect. Please try again.
    </span>
    <span>
      Remember that the ID or NI Number are mandatory. You must fill in at least one of them along with other fields.
    </span>
  </>
);
