import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Page } from "../components";
import { TwoRows } from "../components/Structure";
import { RightContent, LeftContent } from "../components/Content/HomeScreen";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  testMode: process.env.REACT_APP_GA_TEST_MODE,
});
ReactGA.pageview(window.location.pathname + window.location.search);

const HomeScreen = () => (
  <Page id="main">
    <TwoRows>
      <LeftContent />
      <RightContent />
    </TwoRows>
  </Page>
);

export default connect(null, null)(HomeScreen);
