import React from 'react';

export default () => (
  <>
    <span>
      An error has happened during the connection.
    </span>
    <span>
      Please try again in a few minutes.
    </span>
  </>
);
