const INITIAL_STATE = {
  data: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'GET_RENEWAL_TEST_FULFILLED':
      return {
        ...state,
        data: payload.data.data,
      };
    case 'RESET_RENEWAL_TEST':
      return INITIAL_STATE;
    default:
      return state;
  }
};
