const INITIAL_STATE = {
  surname: '',
  day: '',
  month: '',
  year: '',
  id: '',
  nin: '',
  gdpr: false,
  privacy: false,
  recaptcha: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'SHOW_FORM':
      return {
        ...state,
        ...payload,
      };
    default:
  }
  return { ...state };
};
