import React from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';

import { PageTitle } from '../../Text';
import { Button } from '../../Button';

export default () => (
  <div className="top-content">
    <PageTitle>
      Online Card Checker
    </PageTitle>
    <p>
      <span>
        Search and view card and tests details for individuals.
      </span>
      If you suspect the card you have checked is fraudulent, email details of the person and copy of any card produced to the relevant card scheme email address:
      <a href="mailto:CISRS@jobcards.org" className="external-link"><strong> CISRS@jobcards.org</strong></a>
      {' '}
      or
      <a href="mailto:CPCS@jobcards.org" className="external-link"><strong> CPCS@jobcards.org</strong></a>
    </p>
    <Button classButton="back">
      <RouterLink to="/">
        Back to Search
      </RouterLink>
    </Button>
  </div>
);
