import actions from '../actions/spinnerActions';

const INITIAL_STATE = {
  isLoading: false,
};

// the goals of this reducer is to handle
// the loading of the cards data to display a spinner until all data is gathered
const spinnerReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actions.toggleLoading:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return state;
  }
};

export default spinnerReducer;
