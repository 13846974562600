import React from 'react';

import { PageTitle } from '../../Text';
import { HomeForm } from '../../Form';

export default () => (
  <div className="left-content">
    <PageTitle>
      Online Card Checker
    </PageTitle>
    <span>To make a valid search, you must enter two items of information, one of which must be Individual ID/Registration Number or National Insurance Number.</span>
    <p>
      <span>For example: Surname & Card Number, Surname & NI Number, DOB & Card Number, or DOB and NI Number.</span>
    </p>
    <HomeForm />
  </div>
);
