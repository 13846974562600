import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies
import ImageDefault from './ImageDefault';

const Image64 = ({ data = '' }) => (
  <img src={`data:image/jpeg;base64,${data}`} className="card-image" alt="personal image" />
);

const Image = ({ personalImage }) => {
  let ImageSquare = personalImage;
  return (
    <Image64 data={ImageSquare} />
  );
};
Image.propTypes = {
  personalImage: PropTypes.string,
};

Image.defaultProps = {
  personalImage: ImageDefault.image64,
};

export default Image;
