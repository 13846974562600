import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const Row = ({ children }) => (
  <div className="Row">
    {children}
  </div>
);

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Row.defaultProps = {
  children: [],
};

export default Row;
