import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies
import { Row } from '../Structure';

const Base = ({ children, formTitle, onSubmit }) => (
  <Row>
    {formTitle}
    <form className="FormContainer" onSubmit={onSubmit}>
      {children}
    </form>
  </Row>
);

Base.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  formTitle: PropTypes.string,
  onSubmit: PropTypes.func,
};

Base.defaultProps = {
  children: [],
  formTitle: '',
  onSubmit: () => { },
};
export default Base;
