import _ from 'underscore';

export const renderList = (data = [], renderItem) => data.map(
  (item, index) => {
    const key = _.uniqueId('item');
    return renderItem({ item, index, key });
  },
);

export const fillArrayWithNumbers = (start, amount) => Array.from({
  length: amount,
}, (v, i) => start + i);

export const inArray = (data = [], value) => _.indexOf(data, value) !== -1;

export const removeFromArray = (data = [], value) => _.without(data, value);

export const addToArray = (data = [], value) => [...data, value];

export const filterBy = (data = [], key, regexp = null) => data.filter((item) => {
  if (regexp) {
    return regexp.test(item[key]);
  }
  return item === key;
});

/* Reduce an array by matching on one or more keys
 * 
 */
export const filterOnMultipleFields = (data = [], key = [], regexp = null) => data.filter((item) => {
  if (regexp) {
    var isMatch = false;
    key.forEach((k) => {
      if (true === isMatch) {
        return true;
      } else {
        isMatch = regexp.test(item[k]);
      }
    })
    return isMatch;
  }
  return item === key;
});

export const sortArrayBy = (dataset, key) => dataset.sort((a, b) => a[key] - b[key]);

export default {
  renderList,
};
