
export const SaveForm = (fields) => ({
  type: 'SHOW_FORM',
  payload: { ...fields },
});

export const RemoveStatus = (title = '', statusCode = '') => ({
  type: 'API_RESPONSE',
  payload: { status: 0, data: {}, title, statusCode },
});

export default { SaveForm, RemoveStatus };
