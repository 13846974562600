import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const CardContent = ({ children, className }) => (
  <span className={`cardcontent ${className}`}>
    {children}
  </span>
);

CardContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

CardContent.defaultProps = {
  children: [],
  className: '',
};

export default CardContent;
