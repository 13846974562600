import { combineReducers } from 'redux';
import FormReducer from './formReducer';
import ApiReducer from './apiReducer';
import UserCardsReducer from './userCardsReducer';
import RenewalTestReducer from './renewalTestReducer';
import CardDetailsReducer from './cardDetailsReducer';
import spinnerReducer from './spinnerReducer';

export default combineReducers({
  CardForm: FormReducer,
  Profile: ApiReducer,
  UserCards: UserCardsReducer,
  RenewalTest: RenewalTestReducer,
  CardDetails: CardDetailsReducer,
  spinner: spinnerReducer,
});
