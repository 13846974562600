import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';

import { Page } from '../components';
import { Card } from '../components/Card';
import { TopContent } from '../components/Content/UserDataScreen';
import UserTabs from '../components/UserTabs';
import { resetProfile } from '../redux/actions/apiActions';
import { resetCardDetails } from '../redux/actions/cardDetailsActions';
import { resetCards } from '../redux/actions/userCardsActions';

ReactGA.pageview(window.location.pathname + window.location.search);

/**
 * 1. llamada a userData con la info del usuario
 * 2. llamada a cardByUser con el quartzID -> lista de cards
 * 3. llamada a cardDetails para cada card recibida en cardsByUser
 * 4. llamada a renewal-hse (segunda pestaña)
 */

const UserDataScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // clears the redux state when the page unmounts
    // This avoids showing info from a previous user when searching multiple times
    return () => {
      dispatch(resetCardDetails());
      dispatch(resetCards());
      dispatch(resetProfile());
    };
  }, []);

  return (
    <Page id="main">
      <TopContent />
      <Card />
      <UserTabs />
    </Page>
  );
};

export default UserDataScreen;
