import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const PageTitle = ({ children, className }) => (
  <h3 className={`pagetitle ${className}`}>
    {children}
  </h3>
);

PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

PageTitle.defaultProps = {
  children: [],
  className: '',
};

export default PageTitle;
