import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ErrorFieldContent, ErrorConnectionContent } from '.';

export default ({ statusCode = '' }) => {
  const ErrorText = () => {
    switch (statusCode) {
      case 422:
        return (
          <ErrorFieldContent />
        );
      case 500:
        return (
          <ErrorConnectionContent />
        );
      default:
        return (
          null
        );
    }
  };

  return (
    <div className="error-alert">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <p>
        <ErrorText />
      </p>
    </div>
  );
};
