import React from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import TypeCell from './TypeCell';
import { renderList } from '../../helpers/arrayHelpers';
import cicrs from '../../cicrsCards';

const TBody = ({
  filteredList = [],
}) => {
  const toggleCardsDisplay = (event) => {
    const CardType = event.currentTarget;
    let sibling = CardType.nextElementSibling;
    while (sibling) {
      if (sibling.matches('.card-details')) {
        sibling.classList.toggle('show');
        sibling = sibling.nextElementSibling;
      } else {
        return null;
      }
    }
  };
  const renderCols = (cells) => cells.map((cell, index) => <TypeCell key={`cell${index}`} item={cell} index={index} />);

  const RowCardDetails = ({ cell }) => {
    const { type = '', start_at: startAt = '', end_at: endAt = '' } = cell;
    return (
      <TableRow className="card-details">
        <TableCell>
          {type}
        </TableCell>
        <TableCell>
          {startAt}
        </TableCell>
        <TableCell>
          {endAt}
        </TableCell>
      </TableRow>
    );
  };

  const ListRowItem = ({ rowData = {} }) => {
    const { cells = [], cellsDetails = [] } = rowData;
    const cardName = cells[1];
    const expiryDate = cells[5];
    const expriryDateTimestamp = (new Date(expiryDate.text.split('/').reverse().join('/')).getTime());

    return (
      <>
        <TableRow onClick={toggleCardsDisplay} className="card card-wrapper">
          {renderCols(cells)}
        </TableRow>
        <RenderCardDetails cells={cellsDetails} cardName={cardName} expriryDateTimestamp={expriryDateTimestamp} />
      </>
    );
  };

  const RenderCardDetails = ({ cells = [], cardName = [], expriryDateTimestamp = 0 }) => {
    const cardCheck = cardName.text.replace(/\s+/g, '-').toLowerCase();
    const cisrsCards = cicrs.cards;
    const dateNow = Date.now();

    if (dateNow > expriryDateTimestamp) {
      return cisrsCards.includes(cardCheck) ? null : <RenderCardDetailsCols cells={cells} />;
    }
    return <RenderCardDetailsCols cells={cells} />;
  };

  const renderListRow = ({ item: rowData = {}, key = null }) => (
    <ListRowItem rowData={rowData} key={key} />
  );

  const RenderCardDetailsCols = (
    {
      cells = [],
    },
  ) => cells.map((cell, index) => <RowCardDetails cell={cell} key={`cell-card${index}`} />);
  return (
    <TableBody>
      {renderList(filteredList, renderListRow)}
    </TableBody>
  );
};

export default TBody;
