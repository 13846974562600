import React from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';

import { PageTitle } from '../../Text';
import { Button } from '../../Button';

export default ({ title = '' }) => (
  <div className="error-content">
    <PageTitle>
      {title}
    </PageTitle>
    <p>
      <span>
        No Records found. Please confirm the details that have been entered.
      </span>
      <span>
        If the details are correct, please contact our helplines:
      </span>
      <span>
        <strong>CISRS</strong>
        {' '}
        (scaffolding) on
        {' '}
        <a href="tel:+08448157223" className="external-link"><strong>0844 815 7223</strong></a>
      </span>
      <span>
        <strong>CPCS</strong>
        {' '}
        (plant) on
        {' '}
        <a href="tel:+03009991177" className="external-link">
          <strong> 0300 999 1177</strong>
          {' '}
          option 2
        </a>
      </span>
    </p>
    <Button classButton="back">
      <RouterLink to="/">
        Back to Search
      </RouterLink>
    </Button>
  </div>
);
