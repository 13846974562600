const INITIAL_STATE = {
  data: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'GET_CARD_DETAILS_FULFILLED':
      return {
        ...state,
        data: state.data.concat(payload),
      };
    // this case works for storing multiple card details at once
    case 'GET_CARDS_DETAILS_FULFILLED':
      return {
        ...state,
        data: payload,
      };
    case 'RESET_CARD_DETAILS':
      return INITIAL_STATE;
    default:
      return state;
  }
};
