import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles/';
import CardLoader from '../CardLoader/CardLoader';
import { Row } from '../Structure';
import InputCheckbox from '../Form/InputCheckbox';
import { TableSortable } from '../Table';
import useIsLoading from '../../hooks/useIsLoading';

import { withTableHeader, withTableHeaderTest, withTableBody, withTableBodyTest, withProfileData, withUserCardsData, withRenewalTestData } from '../../hocs/withData';
import withGetUserCardsActions from '../../hocs/withActions/withGetUserCardsActions';

const TabPanel = ({ active = false, children = null }) => {
  if (!active || !children) return null;
  return <Box>{children}</Box>;
};

const CustomTabs = withStyles({
  root: {
    borderBottom: '2px solid rgba(151, 151, 151, 0.3)',
  },
  indicator: {
    backgroundColor: '#00A7B8',
    borderBottom: '3px solid #00A7B8',
  },

})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: 'rgba(151, 151, 151, 1)',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab {...props} />);

const UserTabs = ({
  UserCards = {},
  Profile = {},
  data = [],
  dataTest = [],
  RenewalTest = {},
  cards = {},
  tests = {},
  CardDetails = {}
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleOnChange = (e, newActiveTab) => setActiveTab(newActiveTab);
  const [expired, setExpired] = useState(Date.now());

  const handleChange = (event) => {
    const { checked } = event;
    if (checked === true) {
      setExpired(-12221881104000);
    } else {
      setExpired(Date.now());
    }
  };

  const isLoading = useIsLoading();
  if (isLoading) return <CardLoader />;

  return (
    <div className="userTabs">
      <div className="tabsHeader">
        <CustomTabs
          value={activeTab}
          onChange={handleOnChange}
        >
          <CustomTab label="Card and Tests" className={activeTab === 0 ? 'activeTabLabel' : ''} />
          <CustomTab label="HS&E/Renewal Tests" className={activeTab === 1 ? 'activeTabLabel' : ''} />
        </CustomTabs>
      </div>
      <span className="underline" />
      <div className="tabContent">
        <TabPanel active={activeTab === 0}>
          <div className="tabHeaderCheckbox">
            <Row>
              <InputCheckbox
                name="expired"
                onChange={handleChange}
              >
                Include Expired Records
              </InputCheckbox>
            </Row>
          </div>
          <TableSortable
            expired={expired}
            dataTable={UserCards}
            data={data}
            cards={cards}
            cardDetails={CardDetails}
          />
        </TabPanel>
        <TabPanel active={activeTab === 1}>
          <TableSortable
            dataTable={RenewalTest}
            data={dataTest}
            cards={tests}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default compose(
  connect(
    null,
    null,
  ),
  withGetUserCardsActions,
  withUserCardsData,
  withProfileData,
  withTableBody,
  withTableBodyTest,
  withTableHeader,
  withTableHeaderTest,
  withRenewalTestData,
)(UserTabs);
