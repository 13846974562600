import React from 'react';
import ReactGA from 'react-ga';
import { compose } from 'redux';

import { Page } from '../components';
import { Content } from '../components/Content/ErrorScreen';
import { withProfileData } from '../hocs/withData';

ReactGA.pageview(window.location.pathname + window.location.search);

const ErrorScreen = ({ Profile = {} }) => {
  const title = Profile.title || '';

  return (
    <Page id="main">
      <Content title={title} />
    </Page>
  );
};

export default compose(
  withProfileData,
)(ErrorScreen);
