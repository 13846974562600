import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const TwoRows = ({ children }) => (
  <div className="ThreeRows">
    {children}
  </div>
);

TwoRows.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

TwoRows.defaultProps = {
  children: [],
};

export default TwoRows;
