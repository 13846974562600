import React, { useState } from 'react';
import { Table, Paper } from '@material-ui/core';
import THeader from './THeader';
import TBody from './TBody';


const TableSortable = ({
  data = [],
  expired = -12221881104000,
  cards = {},
  cardDetails = {}
}) => {
  const [sortBy, setSortBy] = useState('membership');
  const [sortByType, setSortByType] = useState('string');
  const [sortDirection, setSortDirection] = useState(1);


  const FilterDateList = () => {

    const dateNow = expired;
    const expiredData = data;
    const newData = expiredData.filter((values) => {
      const ExpiryDate = '16/09/1582';
      const MaxExpiryDate = values.item.max_expiration_date || ExpiryDate;
      const timeReverse = MaxExpiryDate.split('/').reverse().join('/');
      const dateTimestamp = (new Date(timeReverse)).getTime();
      if (dateNow < dateTimestamp) {
        return values;
      }
    });
    return newData;
  };

  const dataList = () => {
    const newData = FilterDateList();
    const isBigger = (a = '', b = '') => a.toLowerCase() > b.toLowerCase();
    const sortDataList = (a, b) => {
      if (sortByType === 'date') {
        const dateDataA = a.item[sortBy].split('/').reverse().join('');
        const dateDataB = b.item[sortBy].split('/').reverse().join('');
        return (
          isBigger(
            dateDataB, dateDataA,
          )
            ? sortDirection * 1
            : sortDirection * -1
        );
      }

      return (
        isBigger(
          b.item[sortBy], a.item[sortBy],
        )
          ? sortDirection * 1
          : sortDirection * -1
      );
    };
    return newData.sort(sortDataList);
  };

  const handleToggleSortList = (item) => {
    const direction = item.key === sortBy
      ? -1 * sortDirection
      : 1;

    setSortDirection(direction);
    setSortBy(item.key);
    setSortByType(item.sortByType);
  };

  const filteredList = dataList();
  return (
    <Paper>
      <Table className="endorsement-table">
        <THeader
          onSelectSortSelected={handleToggleSortList}
          sortBy={sortBy}
          direction={sortDirection === 1 ? 'asc' : 'desc'}
          cards={cards}
        />
        <TBody
          filteredList={filteredList}
          cardDetails={cardDetails}
        />
      </Table>
    </Paper>
  );
};

export default TableSortable;
