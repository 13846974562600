import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Routes from './routes';

import HomeScreen from '../screens/Home';
import ErrorScreen from '../screens/ErrorScreen';
import UserDataScreen from '../screens/UserDataScreen';

const { userScreen, errorScreen, home } = Routes;

export default () => (
  <Router basename={process.env.REACT_APP_SUBFOLDER}>
    <Switch>
      <Route path={userScreen.path} render={(props) => <UserDataScreen {...props} {...userScreen.props} />} />
      <Route path={errorScreen.path} component={ErrorScreen} {...errorScreen.props} />
      <Route path={home.path} render={(props) => <HomeScreen {...props} {...home.props} />} />
    </Switch>
  </Router>
);
