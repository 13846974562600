import React from 'react';
import Twitter from '../assets/images/twitter.png';
import LinkedIn from '../assets/images/linkedin.png';
import Facebook from '../assets/images/facebook.png';
import Instagram from '../assets/images/instagram.png';
import NOCNLogo from '../assets/images/logoNOCN.png';

const Footer = () => (
  <footer>
    <div className="container">
      <a href="https://www.nocnjobcards.org" target="_blank" rel="noopener noreferrer">
        <img className="logo" alt="corporative logo NOCN" src={NOCNLogo} width="120" height="144" />
      </a>
      <div className="footer-info">
        <p className="copyright">
          ©
          {(new Date().getFullYear())}
          {' '}
          NOCN Job Cards
        </p>
        <p className="company">Company No: 03829217</p>
      </div>
      <div className="legal-links">
        <a href="https://www.nocnjobcards.org/accessibility" target="_blank" rel="noopener noreferrer">Accessibility</a>
        <a href="https://www.nocnjobcards.org/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
      </div>
      <div className="socials">
        <p> Connect with us</p>
        <div className="social-icons">
          <a href="https://www.twitter.com/NOCNGroup" target="_blank" rel="noopener noreferrer">
            <img src={Twitter} alt="twitter social icon" width="36" height="36" />
          </a>
          <a href="https://uk.linkedin.com/company/nocn" target="_blank" rel="noopener noreferrer">
            <img src={LinkedIn} alt="linkedin social icon" width="36" height="36" />
          </a>
          <a href="https://www.facebook.com/NOCNGroup" target="_blank" rel="noopener noreferrer">
            <img src={Facebook} alt="facebook social icon" width="36" height="36" />
          </a>
          <a href="https://www.instagram.com/nocn1" target="_blank" rel="noopener noreferrer">
            <img src={Instagram} alt="instagram social icon" width="36" height="36" />
          </a>
        </div>
      </div>
    </div>
  </footer>
);
export default Footer;
