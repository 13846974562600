import { connect } from 'react-redux';

const CardsRemoveDate = [
  'trained-operator',
  'cpcs-technical-test-(not-card)',
];

const mapStateToProps = ({ UserCards }) => {
  const Cards = UserCards.data.map((Card) => {
    const CardType = Card.card_type_issued;
    const Type = CardType.replace(/\s/g, '-').toLowerCase();
    const RemoveDate = CardsRemoveDate.includes(Type);
    if (RemoveDate === true) {
      Card.end_at = ' ';
    }
  });
  return { UserCards: Cards };
};

export default (WrappedComponent) => connect(mapStateToProps, {})(WrappedComponent);
