import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const InputTitle = ({ children, className }) => (
  <h3 className={`input-title ${className}`}>
    {children}
  </h3>
);

InputTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

InputTitle.defaultProps = {
  children: null,
  className: '',
};

export default InputTitle;
