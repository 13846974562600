import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'underscore';

import { withUserCardsData, withCardDetailsData } from '.';

const filterCardDetails = (CardDetails) => {
  const Cards = CardDetails.filter((card) => {
    if (!_.isEmpty(card.data)) {
      return card;
    }
  });
  return Cards;
};

const formatDataTable = (
  data,
  CardDetails,
) => {
  const dataTable = Object.values(data).map((rowData) => {
    const getCardDetails = (cardId) => CardDetails
      .filter((card) => cardId === card.id)
      .map((card) => card.data.map((cards) => cards))
      .shift();
    return {
      row: {
        selectable: false,
      },
      cells: [
        { text: 'Membership / Endorsement' },
        { text: rowData.card_type_issued },
        { text: 'Start Date' },
        { text: rowData.start_at },
        { text: 'Expiry Date' },
        { text: rowData.end_at },
      ],
      cellsDetails: getCardDetails(rowData.card_id),
      item: rowData,
    };
  });

  return dataTable;
};

const mapStateToProps = ({ UserCards, CardDetails }) => {
  const CardDetailsRows = filterCardDetails(CardDetails.data);
  const data = formatDataTable(UserCards.data, CardDetailsRows);
  return { data };
};

export default (WrappedComponent) => compose(
  connect(
    mapStateToProps,
    null,
  ),
  withUserCardsData,
  withCardDetailsData,
)(WrappedComponent);
