export default {
  home: {
    path: '/',
    props: {
      title: 'Example',
    },
  },
  errorScreen: {
    path: '/error',
    props: {
      title: 'Example',
    },
  },
  userScreen: {
    path: '/user',
    props: {
      title: 'Example',
    },
  },
};
