const INITIAL_STATE = {
  surname: '',
  dob: '',
  day: '',
  month: '',
  year: '',
  id: '',
  nin: '',
  gdpr: false,
  privacy: false,
  title: '',
  statusCode: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'API_RESPONSE':
      return {
        ...state,
        ...payload.data.data,
        status: payload.data,
        title: payload.title,
        statusCode: payload.statusCode,
      };
    case 'API_ERROR':
      return {
        ...state,
        status: payload,
      };
    case 'RESET_PROFILE':
      return INITIAL_STATE;
    default:
  }
  return { ...state };
};
