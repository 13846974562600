export const GetCards = (value) => ({
  type: 'SHOW_CARDS',
  payload: value,
});

export const resetCards = () => ({
  type: 'RESET_API_CARDS',
});

export default { GetCards };
