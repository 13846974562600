import { connect } from 'react-redux';

const mapStateToProps = () => ({
  tests: {
    header: {
      row: {
        selectable: false,
      },
      cells: [
        {
          id: 1,
          type: 'sortable',
          direction: 'desc',
          text: 'Name',
          key: 'name',
          sortByType: 'string',
        },
        {
          id: 2,
          type: 'sortable',
          direction: 'desc',
          text: 'Type',
          key: 'type',
          sortByType: 'date',
        },
        {
          id: 3,
          type: 'sortable',
          direction: 'desc',
          text: 'Start Date',
          key: 'start_at',
          sortByType: 'date',
        },
      ],
    },
  },
});

export default (WrappedComponent) => connect(mapStateToProps, null)(WrappedComponent);
