import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies
import { renderList } from '../../helpers/arrayHelpers';

const Option = ({ value, text }) => <option value={value}>{text}</option>;

const Options = (
  {
    dataSet = [],
  },
) => renderList(dataSet, ({ item, key }) => <Option text={item} key={key} id={key} />);

const Select = ({
  onChange, options = [], name, value,
}) => {
  const handleOnChange = (e) => onChange(e.target);

  return (
    <div className="select">
      <select value={value} name={name} onChange={handleOnChange}>
        <Options
          dataSet={options}
        />
      </select>
      <div className="select-arrow" />
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
};

Select.defaultProps = {
  onChange: () => { },
  name: '',
  value: '',
};
export default Select;
