import React from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line import/no-extraneous-dependencies

const InputCheckbox = ({
  children, placeholder, onChange, required, name,
}) => {
  const handleOnChange = (e) => onChange(e.target);

  return (
    <label className="checkbox-label">
      <input
        type="checkbox"
        placeholder={placeholder}
        name={name}
        className="checkboxInput"
        onChange={handleOnChange}
        required={required}
      />
      <span>{children}</span>
    </label>
  );
};

InputCheckbox.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  required: PropTypes.bool,
  name: PropTypes.string,
};

InputCheckbox.defaultProps = {
  children: null,
  onChange: () => { },
  placeholder: '',
  required: false,
  name: '',
};
export default InputCheckbox;
